import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import GearUpContent from '../components/Events/GearUpContent';

export const GearUpPageTemplate = ({ title, hero, blurbs }) => {
  return (
    <div className="flex flex-col w-full bg-s4tk-white">
      <GearUpContent hero={hero} blurbs={blurbs} />
    </div>
  );
};

const GearUpPage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  console.log(frontmatter);

  return (
    <Layout>
      <GearUpPageTemplate
        hero={frontmatter.hero}
        blurbs={frontmatter.gblurbs}
        title={frontmatter.title}
      />
    </Layout>
  );
};

export default GearUpPage;

export const gearUpPageQuery = graphql`
  query GearUpPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "gear-up-page" } }) {
      frontmatter {
        title
        gblurbs
        hero {
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          text
        }
      }
    }
  }
`;
