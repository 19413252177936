import React from 'react';
import Hero from '../Hero';
import Section from '../Section';

export const GearUpContent = ({ blurbs, hero }) => {
  return (
    <>
      <Hero images={hero.images} title={hero.text} />
      <Section>
        <div className="flex flex-col items-start justify-start w-full space-y-8">
          {blurbs.map((blurb) => (
            <p className="text-3xl font-bold text-left text-s4tk-blue font-baloo sm:text-justify">
              {blurb}
            </p>
          ))}
        </div>
      </Section>
    </>
  );
};

export default GearUpContent;
